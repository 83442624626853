import { format, isValid, parseISO } from "date-fns"

/**
 * Format a date as a string, preserving the date portion regardless of timezone.
 * Use this for non-React contexts (like AG Grid formatters).
 */
export function formatDate(
  date: Date | string | number | null | undefined,
  formatString: string = "MM/dd/yyyy"
): string {
  // Return empty string for null/undefined/empty string
  if (
    date === null ||
    date === undefined ||
    (typeof date === "string" && !date.trim())
  )
    return ""
  // Handle NaN explicitly
  if (typeof date === "number" && isNaN(date)) return "invalid date"

  try {
    const dateObj = typeof date === "string" ? parseISO(date) : new Date(date)
    if (!isValid(dateObj)) return "invalid date"
    // Use UTC methods to get the date components to avoid timezone shifts
    const year = dateObj.getUTCFullYear()
    const month = dateObj.getUTCMonth()
    const day = dateObj.getUTCDate()
    // Create a new date with these components
    const localDate = new Date(year, month, day)
    return format(localDate, formatString)
  } catch {
    return "invalid date"
  }
}

interface DateFormatterProps {
  date: Date | string | null | undefined
  /**
   * Optional format string using date-fns format tokens
   * @default 'MM/dd/yyyy'
   * @see https://date-fns.org/v2.30.0/docs/format
   */
  formatString?: string
}

/**
 * React component for formatting dates.
 *
 * @example
 * // Basic usage with default MM/dd/yyyy format
 * <DateFormatter date={someDate} />
 *
 * // YYYY-MM-DD format (for tables/forms)
 * <DateFormatter date={someDate} formatString="yyyy-MM-dd" />
 */
export function DateFormatter({
  date,
  formatString = "MM/dd/yyyy",
}: DateFormatterProps) {
  return formatDate(date, formatString)
}
